import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import { Grid } from '@mui/material'
import Spinner from 'react-spinner-material'
import Sidebar from '../components/sidebar'
import Layout from '../components/layout'
import Seo from '../components/seo'
import PageHeader from '../components/pageheader'

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'
const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};
class OrderProblemPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      viewportWidth: isClient ? window.innerWidth : 0,
      loading: true,      
      orderitems: [],
    }
    this.changeCookie = this.changeCookie.bind(this);
    this.switchPage = this.switchPage.bind(this);
  }
  switchPage(pagename){
    this.setState({
        loading: true,
     });
     navigate(pagename)
  };
  changeCookie(cookieName, newState) {
    const { cookies } = this.props
    cookies.set(cookieName, newState, { path: '/' })
    this.setState({ cookieName: newState })
  }
  updateWindowDimensions = () => {
    // console.log(window.innerWidth)
    this.setState({ viewportWidth: window.innerWidth })
  }  
  componentDidMount() {
    // console.log(this.props.allCookies)
    this.changeCookie('lastpage', '/')
    // if (!this.props.allCookies.firstload) {            
    //   setTimeout(() => {
    //     navigate('/loading')
    //   }, 500)
    // }
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
    if(this.props.allCookies.orderitems) {
      this.setState({
        orderitems: this.props.allCookies.orderitems
        
      })
    }
    this.setState({ loading: false })
  }
  componentWillUnmount() {
    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions)
  }

  render() {
    const siteTitle = "Ready Made Meals"
    const keywords = ["ready made meals", "healthy", "dinner", "family", "building futures", "montessori"]
    const pageName = 'Order Problem'
    
    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }
    var spacercol = 1;
    var contentcol = 10;
    if(pagewidth) {
      var spacercol = 0;
      var contentcol = 12;
    }

    return (
      <React.Fragment>
        <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <Sidebar switchPage={this.switchPage} orderitems={this.state.orderitems} removeitemfromorder={this.removeitemfromorder}/>
          {this.state.loading ? (
            <div className="spinneroverlay">
              <div className="spinnercontainer">
                <Spinner
                  radius={100}
                  color={'#ff4c76'}
                  stroke={2}
                  visible={true}
                />
              </div>
            </div>
          ) : (
            <div />
          )}
          
          <div className="page_wrapper generalpage_wrapper">
            <PageHeader pagewidth={pagewidth.matches} pagetitle={pageName} />
            <Grid container spacing={0} >
              <Grid item xs={12} >
                <Grid container spacing={0} >
                  <Grid item xs={spacercol} />
                  <Grid item xs={contentcol} >
                    <div className='generalpage_content'>                      
                      <div className="contentcontainer">                      
                        <Grid container spacing={0}>                          
                          <Grid item xs={12}>                            
                            <h4>
                              There was a problem processing your order, please try again or contact Ready Made Meals at admin@buildingfutures.com.au
                            </h4>
                          </Grid>                          
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </Grid>
            </Grid>

          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(OrderProblemPage)
