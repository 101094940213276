import * as React from 'react'
import { Grid } from '@mui/material'
import baselogo from '../assets/images/bfm-logo.png'

class PageHeader extends React.Component {
  render() {
    var pageHeader = (
      <div className="pagesection_main">
        <div className="container">
          <hr style={{ border: '0.5px solid #ff4c76' }} />
          <div className="row">
            <div className="col-12">
              <header>
                <Grid container spacing={0}>
                <Grid item xs={6} md={9}>
                    <h1 style={{ color: '#757575' }}>{this.props.pagetitle}</h1>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <img className='mainlogo' src={baselogo} />
                  </Grid>              
                </Grid>
              </header>
            </div>        
          </div>
          <hr style={{ border: '0.5px solid #ff4c76' }} />
        </div>
      </div>
    )
    var pageHeaderMob = (
      <div className="pagesection_main">
        <div className="container">
          <hr style={{ border: '0.5px solid #ff4c76' }} />
          <div className="row">
            <div className="col-12">
              <header>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <img className='mainlogo' src={baselogo} />
                  </Grid> 
                  <Grid item xs={12}>
                    <h1 style={{ color: '#757575' }}>{this.props.pagetitle}</h1>
                  </Grid>                               
                </Grid>
              </header>
            </div>        
          </div>
          <hr style={{ border: '0.5px solid #ff4c76' }} />
        </div>
      </div>
    )
    return (
      <div>
        {this.props.pagewidth ? pageHeaderMob : pageHeader}
      </div>
      
    )
  }
}
export default PageHeader
